var $class="se2--navigation-breadcrumbs",$name="NavigationBreadcrumbs",$path="app/components/NavigationBreadcrumbs/index.js",$this={$class,$name,$path,};import { headerClassName } from 'app/components/HeaderSE/config';
import isTransparentHeader from 'app/components/utilities/isTransparentHeader';

import shell from 'app/modules/shell';
import initializeSticky from 'app/modules/sticky/initializeSticky';
import recalculateStickyPositions from 'app/modules/sticky/recalculateStickyPositions';
import setStickyAnimation from 'app/modules/sticky/setStickyAnimation';

const stickyClassName = 'sticky';
const invisibleClassName = 'invisible';
let isVisible = false;

export default shell.registerComponent($this, ({
  addEventListener, configs, mount, publishMessage, subscribeToMessage,
}) => {
  const hideBreadcrumbs = (element) => {
    element.classList.add(invisibleClassName);
    isVisible = false;
  };

  const showBreadcrumbs = (element) => {
    element.classList.remove(invisibleClassName);
    isVisible = true;
  };

  const toggleVisibilityOnDesktop = (element, toggle) => {
    toggle(element);
    publishMessage(configs.messages.refreshTimelines);
  };

  const toggleVisibilityOnMobile = (element) => {
    const { innerWidth, pageYOffset } = window;
    if (!(innerWidth < configs.breakpoints.xl.min)) return;

    if (pageYOffset > 0 && isVisible) {
      hideBreadcrumbs(element);
      publishMessage(configs.messages.breadcrumbs, { isVisible: false });
    } else if (pageYOffset === 0 && !isVisible) {
      element.style.removeProperty('transform');
      showBreadcrumbs(element);
      publishMessage(configs.messages.breadcrumbs, { isVisible: true });
    }
  };

  const hideBreadcrumbsAtTopOnDesktop = (element) => {
    // Breadcrumbs should be hidden at the top of viewport on desktop resolution
    const { innerWidth, pageYOffset } = window;

    if (
      innerWidth >= configs.breakpoints.xl.min
      && pageYOffset === 0
      && isVisible
    ) {
      toggleVisibilityOnDesktop(element, hideBreadcrumbs);
      publishMessage(configs.messages.breadcrumbs, { isVisible: false });
    }
  };

  const removeFocus = () => {
    if (document.activeElement.closest(`.${$class}`)) document.activeElement.blur();
  };

  const setStickyMarkerPosition = (marker) => {
    // We should manually set correct position to sticky marker
    // for breadcrumbs when a header is transparent. All because
    // transparent header has a placeholder with height 40px.
    if (isTransparentHeader() && !marker.style.top) {
      const header = document.querySelector('header');
      marker.style.position = 'absolute';
      marker.style.top = `${header.offsetHeight}px`;
    }
  };

  mount((element) => {
    const marker = element.previousElementSibling;
    const isBreadcrumbsStuck = false;
    const stickBreadcrumbs = () => {
      if (isBreadcrumbsStuck) return;
      element.classList.add(stickyClassName);
      setStickyMarkerPosition(marker);
      setStickyAnimation(element, marker, subscribeToMessage);
    };

    hideBreadcrumbs(element);
    toggleVisibilityOnMobile(element);
    initializeSticky(element);
    addEventListener(element, 'focusin', recalculateStickyPositions);
    addEventListener(element, 'focusout', recalculateStickyPositions);
    subscribeToMessage(
      configs.messages.breadcrumbs,
      // eslint-disable-next-line no-shadow
      ({ isVisible }) => {
        toggleVisibilityOnDesktop(
          element, isVisible ? showBreadcrumbs : hideBreadcrumbs,
        );
      },
    );
    addEventListener(window, 'scroll', () => {
      toggleVisibilityOnMobile(element);
      removeFocus();
    });
    addEventListener(window, 'resize', () => {
      toggleVisibilityOnMobile(element);
      setStickyMarkerPosition(marker);
      hideBreadcrumbsAtTopOnDesktop(element);
    });

    if (document.querySelector(`.${headerClassName}`)) {
      stickBreadcrumbs();
    } else {
      subscribeToMessage(
        configs.messages.headerMount,
        stickBreadcrumbs,
      );
    }
  });
});
